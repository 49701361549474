<template>
  <div class="service-area pt--70 pb--50 bg_color--1">
    <v-container>
      <v-row>
        <v-col md="8" offset-md="2" cols="12">
          <div class="text-center section-title mb--30">
            <h2 class="heading-title">
              <span class="app-title-text-left">Application-Pack</span> Download
            </h2>
            <p class="description">
              You may download and complete the application pack and e-mail to
              info@rhs24.cloud or post to the address detailed below.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <div
            data-aos="zoom-in-right"
            data-aos-easing="ease"
            data-aos-duration="500"
            class="app-application-pack"
          >
            <div class="pack-title">Application pack</div>
            <div v-html="iconSvg('file-text')"></div>
            <div>
              <v-btn
                href="/home/download?filename=RHS24_ApplicationPack_v1.00.docx"
                color="primary"
                block
                large
                download
              >
                Download <v-icon right> mdi-cloud-download </v-icon></v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import feather from "feather-icons";
export default {
  data() {
    return {};
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style>
.app-application-pack {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 5px;
  width: 165px;
}
.app-application-pack .pack-title {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.app-application-pack svg {
  width: 150px;
  height: 150px;
  color: #dddddd;
}
</style>
